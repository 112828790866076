main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;
}

* {
  box-sizing: inherit;
}

ul, ol {
  // doesn't do RTL, it break a sidebar
  padding-left: 15px;
  margin-bottom: 0;
  list-style-image: url('../../shared/img/circle.svg');
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

* {
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f6f6f6;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
  border: 3px solid #f6f6f6;
}

div[class~='col'] {
  margin: 0;
  padding: 0;
}