$spiner-items: 12; //Make sure you have enough DOM elements
$spinner-item-width: 2px;
$spinner-dimension: 32px;
$spinner-item-background-color: #666;
$buttonSpinner-item-background-color: rgb(255, 255, 255);

.spinner-wrapper {
  height: $spinner-dimension;
  width: $spinner-dimension;
}

.spinner-container {
  height: $spinner-dimension;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $spinner-dimension;

  .spinner {
    background: $spinner-item-background-color;
    height: $spinner-item-width;
    width: 25%;

    &.spinner {
      left: 50%;
      opacity: .3;
      position: absolute;
      top: 50%;
      transform-origin: -100% 0;
    }

    &.buttonSpinner {
      background: $buttonSpinner-item-background-color
    }

    @for $spinner from 0 through $spiner-items - 1 {
      &.spinner-#{$spinner} {
        $rotate: (360 / $spiner-items) * $spinner;
        animation: spinner-animation #{($spiner-items * 1) / 15}s #{($spinner)/15}s infinite;
        transform: translate(100%, 0) rotate(#{$rotate}deg);
      }
    }

    @at-root {
      @keyframes spinner-animation {
        0% {
          opacity: .3
        }
        10% {
          opacity: 1
        }
        100% {
          opacity: .3;
        }
      }
    }

  }
}