@import "src/scss/settings/variable";

.modal-dialog {
  width: 100%;
  max-width: 590px;
  max-height: 662px;

  @media screen and (max-width: 630px){
    margin: 0;
    width: 100vw;
    max-height: 100%;
    height: 100%;
  }
}

.modal-header {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 24px;
  margin: 0;
}

.modal-body,
.modal-body > * {
  margin: 0 -32px;
  padding: 0 32px;
}

.modal-body a {
  word-break: break-word;
}

.modal-content {
  border-radius: 0;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 48px 32px;

  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }

  @media screen and (max-width: 630px){
    max-height: 100vh !important;
    height: 100vh;
  }
}

.modal-body {
  overflow-x: hidden;
}

.modal-footer {
  margin: 0;
  padding: 32px 0 0;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  & button {
    width: 105px;
  }
}

.modal__close-btn,
.modal__back-btn {
  position: absolute;
  top: 8px;

  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.modal__back-btn {
  @include directify($directions) {
    #{directed('left')}: 8px;
  }
}

.modal__close-btn {
  @include directify($directions) {
    #{directed('right')}: 8px;
  }
}

.modal-dialog--primary {
  .modal__title-icon {
    color: $color-blue;
  }
}
