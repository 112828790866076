@import "src/scss/settings/variable";

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  &.blue p {
    color: $color-blue;
  }

  &.blue:hover p {
    color: $color-blue-hover;
  }

  &.red p {
    color: red;
  }

  &.red:hover p {
    color: red;
  }
}
