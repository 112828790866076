@import 'src/scss/settings/variable';

.alert {
  width: 100%;
  border-radius: 0;
  position: relative;
  margin-bottom: 10px;
  padding: 0;
  display: flex;

  &__container.slideUp {
    animation: slideUp .5s ease-in-out;
  }

  &__container {
    z-index: 1000;
    position: absolute;
    top: 8px;
    left: calc(100vw/2 - 260px);
    width: 520px;
    height: 43px;
    animation: slideDown .5s ease-in-out;
    
    @media screen and (max-width: 580px){
      width: 100%;
      top: 0;
      left: 0;
    }
  }
  
  @keyframes slideDown {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }
  
  @keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: $color-white;
    margin-top: 0;
  }

  &__content {
    padding: 10px 20px;
    color: $color-white;
  }

  &.alert-info {
    background-color: $color-blue;
    border-color: $color-blue;

    &.alert--bordered .alert__icon {
      background-color: $color-blue;
    }

    &.alert--colored .alert__icon {
      background-color: $color-light-blue;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-blue;
    }
  }

  &.alert-success {
    background-color: $color-green;
    border-color: $color-green;

    &.alert--bordered .alert__icon {
      background-color: $color-green;
    }

    &.alert--colored .alert__icon {
      background-color: $color-green-hover;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-green;
    }
  }

  &.alert-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;

    &.alert--bordered .alert__icon {
      background-color: $color-yellow;
    }

    &.alert--colored .alert__icon {
      background-color: $color-light-yellow;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-yellow;
    }
  }

  &.alert-danger {
    background-color: $color-red;
    border-color: $color-red;

    &.alert--bordered .alert__icon {
      background-color: $color-red;
    }

    &.alert--colored .alert__icon {
      background-color: $color-light-red;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-red;
    }
  }
}