.ps-grid {
  display: flex;
  margin: 0;
  padding: 0;

  &__table {
    gap: 24px 26px;
    margin-right: -26px;
  }

  @each $columns in 1 2 3 4 5 6 7 8 9 10 11 12 {
    &__columns-#{$columns} {
      flex-basis: calc((100% - 26px * 11) * $columns + (26px * ($columns - 1)));
    }
  }
}